"use client"

import { useState } from 'react'
import dynamic from 'next/dynamic'

import UserSessionNav, {
	UserSessionNavLogout,
} from '@components/session/UserNav'
import _Logo from './Logo'
import Link from 'next/link'
import ListOut from './ListOut'
import NavLink from './NavLink'
import NavLinkColor from './NavLinkColor'
import {
	AddBuyCarButton,
	AddOfferButton,
	LoginButton,
} from './NavbarButtons'
// import Lang from '@components/lang/Lang'
import Watched from '@components/links/Watched'
import Maybe from './Maybe'
import Icon from './Icon'
import Chat from '@components/chat/nav/Icon'

import useSWR from 'swr'
import storage from '@utils/localStorage'
import checkLogin from '@utils/checkLogin'
import mix from '@utils/styles/mix'

import useMediaQueryBreakpoint from '@hooks/useMediaQueryBreakpoint'
import useWindowScrollDirection from '@hooks/useWindowScrollDirection'
import useHamburger from '@hooks/useHamburger'

import {
	FIRM_BASE_PAGE,
	NEWS_PAGE,

	// INDUSTRY_GROUP_PAGE,
	ADD_OFFER_PAGE,
	LOGIN_PAGE,
	// SALE_DOC_PAGE,
	COLISION_DOC_PAGE,
	SALE_BUY_DOC_PAGE,
	ADD_PARTNER_PRODUCT,
	ADD_PARTNER_ARTICLE,
} from '@constants/routes'

import BREAKPOINTS from '@constants/breakpoints'

import styles from 'styles/Navbar.module.css'

const HOST = process.env.NEXT_PUBLIC_HOST

const firmBaseLink = { t: 'Baza firm', to: HOST + FIRM_BASE_PAGE }
const blogLink = { t: 'Wiadomości motoryzacyjne', to: HOST + NEWS_PAGE }

// const industryGroupPageLink = { t: 'Grupa WhatsApp', to: INDUSTRY_GROUP_PAGE }

const links = [firmBaseLink, blogLink]

const mobileLinks = [firmBaseLink, blogLink]

const desktopLinks = [
	// { t: 'Umowa kupna sprzedaży', to: SALE_DOC_PAGE },
]

const docLinks = [
	...desktopLinks,
	{ t: 'Oświadczenie sprawcy kolizji', to: HOST + COLISION_DOC_PAGE },
	{ t: 'Zgłoszenie sprzedaży samochodu', to: HOST + SALE_BUY_DOC_PAGE },
]

const trailingLinks = [
	// industryGroupPageLink,
]

const addOfferLink = { t: 'Dodaj ogłoszenie', to: HOST + ADD_OFFER_PAGE }
const addPartnerProductLink = {
	t: 'Dodaj produkt',
	to: HOST + ADD_PARTNER_PRODUCT,
}
const addPartnerArticleLink = {
	t: 'Dodaj artykuł',
	to: HOST + ADD_PARTNER_ARTICLE,
}
const loginLink = { t: 'Zaloguj się', to: HOST + LOGIN_PAGE }
const desktopLinkStyle = { marginRight: 36 }

const Navbar = ({ logoOnly, navdown, nonavshadow, ...rest }) => {
	const [direction] = useWindowScrollDirection()
	const [locked, setLocked] = useState(false)

	const visibility =
		direction === useWindowScrollDirection.DIRECTIONS.up || locked
			? styles.visible
			: styles.hidden

	const showHamburger = useMediaQueryBreakpoint(
		BREAKPOINTS.showHamburger,
	)

	return (
        <nav
			className={mix([
				styles.navbar,
				navdown && styles['nav-down'],
				nonavshadow && styles['no-shadow'],
				visibility,
			])}
			data-navdown={navdown}
			id="main-navb"
			{...rest}
		>
			{logoOnly && <Logo />}
			{!logoOnly &&
				(showHamburger ? (
					<MobileLayout lock={setLocked} />
				) : (
					<DesktopLayout />
				))}

			{/* { logoOnly && <Lang /> } */}
		</nav>
    );
}

function MobileLayout({ lock }) {
	const { data: user } = useSWR('user', storage)
	const isLoggedIn = checkLogin(user)

	const Hamburger = useHamburger(lock, [user?._id])

	return (
		<>
			{/* <Lang /> */}
			<Hamburger />
			<Logo />
			<AddOfferButton isLoggedIn={isLoggedIn} />
			<Hamburger.Menu>
				{/* <AddBuyCarButton style={{ width: '100%' }} /> */}

				<Maybe test={!isLoggedIn}>
					<ListOut id="t" items={[loginLink]} Component={NavLink} />
				</Maybe>

				<Maybe test={isLoggedIn}>
					<Watched text />
					<br />
					<UserSessionNav user={user} alwaysOpen mobile nologout />
					<hr />
				</Maybe>

				<ListOut id="t" items={mobileLinks} Component={NavLink} />
				<ListOut id="t" items={docLinks} Component={NavLink} />
				<ListOut id="t" items={trailingLinks} Component={NavLink} />
				<hr />
				<UserSessionNavLogout user={user} mobile />
				<br />
			</Hamburger.Menu>
		</>
	)
}

function DesktopLayout() {
	const { data: user } = useSWR('user', storage)
	const isLoggedIn = checkLogin(user)

	return (
		<>
            <Logo />

			<ListOut
				id="t"
				items={links}
				Component={NavLink}
				style={desktopLinkStyle}
			/>
			<ListOut
				id="t"
				items={desktopLinks}
				Component={NavLink}
				style={desktopLinkStyle}
			/>
			<ListOut
				id="t"
				items={trailingLinks}
				Component={NavLink}
				style={desktopLinkStyle}
			/>

			<Chat />
			{/* <Lang /> */}
			<Maybe test={isLoggedIn}>
				<Watched />
			</Maybe>

			<Maybe test={user?.is_partner}>
				<DoubleLink>
					<NavLinkColor
						{...addPartnerProductLink}
						color="blue"
						iconLeft={<Icon>add</Icon>}
						half
					/>
					<NavLinkColor
						{...addPartnerArticleLink}
						color="blue"
						iconLeft={<Icon>add</Icon>}
						half
					/>
				</DoubleLink>
			</Maybe>

			<AddBuyCarButton />

			<AddOfferButton isLoggedIn={isLoggedIn} />

			<Maybe test={!isLoggedIn}>
				<LoginButton />
			</Maybe>

			<Maybe test={isLoggedIn}>
				<UserSessionNav user={user} />
			</Maybe>
		</>
	)
}

function DoubleLink({ children }) {
	return <div>{children}</div>
}

function Logo () {
	return (
		<Link href={HOST} prefetch={false} className={styles.logo}>
			<_Logo />
		</Link>
	)
}

const NavbarNoSSR = dynamic(() => Promise.resolve(Navbar), {
	ssr: false,
})

export default NavbarNoSSR
export { addOfferLink, addPartnerProductLink, addPartnerArticleLink }
